<template>
  <div class="Banner" :style="{background: 'url(' + coverImgUrl + ') no-repeat center center'}">
    <div class="BannerBox">
      <div class="BannerBoxC" :class="{'BannerBoxC1':isActive}">
        <p class="p1" :class="{'leftP1':isLeft}">{{ p1 }}</p>
        <span class="span1">{{ span1 }}</span>
        <span class="span2">{{ span2 }}</span>
        <el-button v-if="!isCareer" class="btnLeft" @click="coop" type="primary">合作咨询</el-button>
        <el-button v-else class="btnLeft" @click="allZhiwei" type="primary">全部职位</el-button>
        <el-button v-if="isDiting" class="btnLeft tiyan" @click="goWaibu" type="primary">申请试用</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "hyBanner",
  data() {
    return {
      coverImgUrl: '',
      p1: '',
      span1: '',
      span2: '',
      bottomVal: '',
      isActive: false,
      isCareer: true,
      isDiting: true,
      isLeft: false,
    }
  },
  mounted() {
    if (this.$route.path === '/diting') {
      this.coverImgUrl = require('@/assets/imgs/diting/product_poster.png')
      this.isActive = false
      this.isCareer = false
      this.isDiting = true
      this.isLeft = true
      this.p1 = '“海河·谛听”言语交互大模型'
      this.span1 = '以人的机理为蓝本，将言语信息与语言学、心理学和脑科神经科学相结合，以大模型为基础实现言语意图的深度理解。'
      this.span2 = 'BASED ON THE HUMAN MECHANISM, IT COMBINES SPEECH INFORMATION WITH LINGUISTICS, PSYCHOLOGY AND NEUROSCIENSE; THANKS TO ITS LARGE LANGUAGE MODEL, IT CAN ACHIEVE A DEEP UNDERSTANDING OF THE INTENTION OF A SPEECH'
    } else if (this.$route.path === '/ai') {
      this.coverImgUrl = require('@/assets/imgs/ai/product_poster.jpg')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '慧言AI平台'
      this.span1 = '提供丰富的智能语音，文本处理服务接口，将智能技术迅速集成到您的应用'
      this.span2 = 'SERVICE INTERFACES TO INTELLIGENT SPEECH AND TEXT PROCESSING ARE PROVIDED, THUS QUICKLY INTEGRATING INTELLIGENT TECHNOLOGY INTO YOUR APPLICATIONS'
    } else if (this.$route.path === '/meeting') {
      this.coverImgUrl = require('@/assets/imgs/meeting/product_poster.jpg')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '智能会议系统'
      this.span1 = '为您提供会议场景下的智能语音转写、翻译服务，支持实时上屏、会议纪要、语音翻译等多种功能'
      this.span2 = 'TO PROVIDE YOU WITH INTELLIGENT VOICE TRANSRIPTICN AND TRANSLATION SERVICES IN CONFERENCE SCENES'
    } else if (this.$route.path === '/privatization') {
      this.coverImgUrl = require('@/assets/imgs/private/product_poster.jpg')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '智能语音私有化部署'
      this.span1 = '以私有云计算为基础，具备多语种识别、翻译合成能力，提供集群、单机等多种部署方式，适用于各种智能语音场景'
      this.span2 = 'BASED ON THE PRIVATE CLOUD COMPUTING, IT HAS THE CAPABILITY OF MULTILINGUAL RECOGNITION, TRANSLATION AND SYNTHESIS'
    } else if (this.$route.path === '/sdk') {
      this.coverImgUrl = require('@/assets/imgs/sdk/product_poster.jpg')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '智能语音移动端离线SDK'
      this.span1 = '提供离线SDK工具包，在网络不佳或无网的情况下，为您提供稳定一流的服务，让沟通不受限制'
      this.span2 = 'OFFLINE SDK TOOLKITS ARE PROVIDED TO ENSURE STABLE AND FIRST-CLASS SERVICES FOR YOU IN THE CASE OF POOR OR NO NETWORK LINKS'
    } else if (this.$route.path === '/screen') {
      this.coverImgUrl = require('@/assets/imgs/solution/solution_meeting_poster.png')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '会议大屏解决方案'
      this.span1 = '慧言科技为用户提供语音交互和语音翻译技术，为传统的会议大屏提供实时双语字幕、语音输入、语音搜索、纪要管理等AI功能。'
      this.span2 = 'BACKED BY ITS ADVANCED SPEECH INTERACTION AND TRANSLATION TECHNOLOGIES, HUIYAN OFFERS INTELLIGNET OFFICE SERCICES IN TRADTIONAL CONFERENCE SCENARIOS INCLUDING REAL-TIME BILINGUAL SUBTITLES, VOICE INPUT, VOICE SERACH, AND MINUTES MANAGEMENT.'
    } else if (this.$route.path === '/edu') {
      this.coverImgUrl = require('@/assets/imgs/solution/samrt_edu_poster.png')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '智慧教育解决方案'
      this.span1 = '依托慧言科技先进的语音交互和语音翻译技术，为传统的教学提供课堂实时双语字幕，实时PPT导航，内容智能分析检索等AI功能。'
      this.span2 = 'BACKED BY ITS ADVANCED SPEECH INTERACTION AND TRANSLATION TECHNOLOGIES, HUIYAN OFFERS AI SERVICES IN TRADITIONAL CLASSES INCLUDING REAL-TIME BILINGUAL SUBTITLES AND PPT NAVIGATION, AS WELL AS INTELLIGNET ANALYSIS AND RETRIEVAL OF THE CONTENT.'
    } else if (this.$route.path === '/interpreting') {
      this.coverImgUrl = require('@/assets/imgs/solution/simul_interpreting_poster.png')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '智慧同传解决方案'
      this.span1 = '提供语音翻译能力，旨在解决跨语言沟通问题。'
      this.span2 = 'PROVIDING SPEECH TRANSLATION TO IMPROVE COMMUNICATION BETWEEN SPEAKERS OF DIFFERENT LANGUAGES.'
    } else if (this.$route.path === '/ppp') {
      this.coverImgUrl = require('@/assets/imgs/solution/solution_ppp_poster.png')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '公检法场景解决方案'
      this.span1 = '智能语音技术助力庭审、谈话、审讯场景业务新突破。'
      this.span2 = 'The intelligent speech interaction technology empowers new breakthroughs in courtroom trials, organizational conversations, and interrogation scenarios.'
    } else if (this.$route.path === '/introduction') {
      this.coverImgUrl = require('@/assets/imgs/about/about_poster.jpg')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '掌握核心语音科技'
      this.span1 = '提供智能语音新体验'
      this.span2 = 'MASTER CORE VOICE TECHNOLOGY AND PROVIDE NEW EXPERIENCE OF INTELLIGENT VOICE'
    } else if (this.$route.path === '/career') {
      this.coverImgUrl = require('@/assets/imgs/join/join_poster.jpg')
      this.isActive = false
      this.isCareer = true
      this.isDiting = false
      this.isLeft = false
      this.p1 = '加入我们'
      this.span1 = '共创美好未来'
      this.span2 = 'JOIN US FOR BETTER FUTURE'
    } else if (this.$route.path === '/contact') {
      this.coverImgUrl = require('@/assets/imgs/join/join_poster.jpg')
      this.isActive = false
      this.isCareer = false
      this.isDiting = false
      this.isLeft = false
      this.p1 = '联系我们'
      this.span1 = '共创美好未来'
      this.span2 = 'FOR BETTER FUTURE'
    }
  },
  methods: {
    coop() {
      window.open('mailto:bd@huiyan-tech.com','_blank')
    },
    allZhiwei() {
      window.open('https://www.zhipin.com/gongsir/3df3c961d2b7b5191Hx729W1Fw~~.html','_blank')
    },
    goWaibu() {
      window.open('https://diting.huiyan-tech.com/#/login','_blank')
    },
  }
};
</script>

<style scoped lang="less">
.Banner {
  width: 100%;
  height: 600px;
  background-size: 100% 100%!important;
  .BannerBox {
    display: flex;
    align-items: center;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .BannerBoxC {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: left;
      width: 65%;
      p {
        width: 100%;
        margin-bottom: 16px;
        font-size: 50px;
        font-family: 'siyuan-medium', serif;
        color: #FFFFFF;
      }
      .leftP1 {
        margin-left: -30px;
      }
      .span1 {
        width: 80%;
        margin-bottom: 36px;
        font-size: 20px;
        font-family: 'siyuan-normal', serif;
        font-weight: 400;
        color: #FFFFFF;
      }
      .leftSpan1 {
        margin-left: -10px;
      }
      .span2 {
        width: 80%;
        margin-bottom: 36px;
        font-size: 14px;
        font-family: 'siyuan-normal', serif;
        font-weight: 400;
        color: #FFFFFF;
      }
      button {
        width: 260px;
        height: 60px;
        margin-top: 40px;
        background: #fa8734;
        border-radius: 10px;
        border: none;
        font-size: 26px;
        color: #FFFFFF;
      }
      button.tiyan {
        background: linear-gradient(53deg,#639CFF 5%,#007CFF 94%);
      }
    }
    .BannerBoxC1 {
      margin-bottom: 200px;
    }
  }
}
</style>
