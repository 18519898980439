<template>
  <div class="screen">
    <Banner/>
    <div class="firstPlate">
      <div class="solutionPublicTitle">
        <p>应用介绍</p>
        <p>APPLICATION INTRODUCTION</p>
      </div>
      <div class="appbox white_bg">
        <div class="appboxCenter">
          <div class=""><img src="@/assets/imgs/solution/realtime_subtitles.png" alt=""></div>
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>实时字幕</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <p>实时字幕</p>
              <h6>支持远程视频会议和现场会议的实时字幕</h6>
            </div>
            <div class="yyjs_details">
              <p>多语种支持</p>
              <h6>支持中英日韩等多语种识别，中到英日韩多语种互译</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="appbox gray_bg">
        <div class="appboxCenter">
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>语音输入</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <p>语音输入法</p>
              <h6>支持语音输入，最快400字/秒，提升文字输入效率和便捷性</h6>
            </div>
            <div class="yyjs_details">
              <p>语音搜索</p>
              <h6>支持语音搜索，提升搜索的效率</h6>
            </div>
          </div>
          <div class=""><img src="@/assets/imgs/solution/voice_search.png" alt=""></div>
        </div>
      </div>
      <div class="appbox white_bg">
        <div class="appboxCenter">
          <div class=""><img src="@/assets/imgs/solution/voice_assistant.png" alt=""></div>
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>语音助手</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <p>语音指令</p>
              <h6>一语即达，响应迅速</h6>
            </div>
            <div class="yyjs_details">
              <p>个性定制</p>
              <h6>可定制企业专属唤醒词</h6>
            </div>
          </div>
        </div>
      </div>
      <div class="appbox gray_bg">
        <div class="appboxCenter">
          <div class="hy_yyjs_infos">
            <div class="yyjs_title">
              <span>记录整理</span>
              <b></b>
            </div>
            <div class="yyjs_details">
              <p>重点标记</p>
              <h6>标记会议重点，节省会后时间</h6>
            </div>
            <div class="yyjs_details">
              <p>整理纪要</p>
              <h6>会中同步整理会议纪要，会后即时发送</h6>
            </div>
          </div>
          <div class=""><img src="@/assets/imgs/solution/logging.png" alt=""></div>
        </div>
      </div>
    </div>
    <div class="secondPlate">
      <div class="solutionPublicTitle">
        <p>方案架构</p>
        <p>SOLUTION ARCHITECTURE</p>
      </div>
      <div class="hy_index_fajg">
        <div class="hy_fajg_body">
          <div class="fajg_body_left">
            <div class="fajg_pictures">
              <img src="@/assets/imgs/solution/archi_pic1.png" alt="">
              <img src="@/assets/imgs/solution/archi_pic3.png" alt="">
              <img src="@/assets/imgs/solution/archi_pic2.png" alt="">
            </div>
            <div class="fajg_architecture">
              <div class="fajg_archi_body">
                <h4>应<br />用<br />层</h4>
                <div class="fajg_body_details">
                  <span>语音助手</span>
                  <span>实时字幕</span>
                  <span>语音输入</span>
                  <span>记录整理</span>
                </div>
              </div>
              <div class="fajg_archi_body">
                <h4>S<br />D<br />K</h4>
                <div class="fajg_body_details">
                  <span>指令词识别SDK</span>
                  <span>语音识别SDK</span>
                  <span>机器翻译SDK</span>
                  <span>语音唤醒SDK</span>
                </div>
              </div>
              <div class="fajg_archi_body">
                <h4>基<br />础<br />硬<br />件</h4>
                <div class="fajg_body_details">
                  <span>会议大屏</span>
                  <span>OPS主机</span>
                  <span>麦克风列阵</span>
                </div>
              </div>
            </div>
          </div>
          <div class="fajg_body_right">
            <div class="archi_instruction">
              <h5>架构说明：</h5>
              <p>在OPS主机中集成指令词识别SDK、语音识别SDK及机器翻译SDK，即可为会议大屏添加AI能力。用户通过麦克风列阵向会议大屏发出语音信号，经由OPS中的指令词识别后，启动会议大屏中的相关软件产品，无需手动即可打开各种软件。同时，通过软件中的按钮，实现语音识别及翻译的调用，在会议大屏中实时显示会议的转写和翻译内容，为参会者带来良好的体验。</p>
            </div>
            <div class="recommend_config">
              <h5>OPS配置推荐：</h5>
              <ul>
                <li>操作系统：Windows 10 64-bit</li>
                <li>处理器：Intel® Core® i7-8700 及以上</li>
                <li>内存：16GB及以上</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thirdPlate">
      <div class="solutionPublicTitle">
        <p>合作伙伴</p>
        <p>COOPERATIVE PARTNER</p>
      </div>
      <div class="hy_hzhb_body">
        <div><img src="@/assets/imgs/ZEAVA.png" alt=""></div> <!-- 通彩视听 -->
        <!-- <div><img src="@/assets/imgs/TOG.png" alt=""></div> 童格 -->
        <div style="margin-right: 0;"><img style="width: 100%;height: auto;" src="@/assets/imgs/ison.png" alt=""></div> <!-- 艾素 -->
      </div>
    </div>
    <div class="hy_index_gray">
      <div class="hy_index_hzzx">
        <p><span>点击下方按钮，获得我们的专属支持</span></p>
        <div><button><a href="mailto:bd@huiyan-tech.com" target="_blank">合作咨询</a></button></div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner"
export default {
  name: "Screen",
  components: {
    Banner,
  },
}
</script>

<style lang="less" scoped>
.appbox {
  width: 100%;
  height: 500px;
  .appboxCenter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    img {
      width: 440px;
      height: 330px;
    }
    .hy_yyjs_infos .yyjs_title{
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      height: 50px;
    }
    .hy_yyjs_infos .yyjs_title span{
      color: #000;
      font-size: 30px;
      font-weight: 500;
    }
    .hy_yyjs_infos .yyjs_title b{
      width: 50px;
      height: 3px;
      background-color: #155FA1;
      border-radius: 2px;
    }
    .hy_yyjs_infos .yyjs_details{
      margin-top: 40px;
    }
    .hy_yyjs_infos .yyjs_details p{
      font-size: 24px;
      font-weight: 400;
      color: #000000;
      line-height: 33px;
      text-align: center;
    }
    .hy_yyjs_infos h6{
      font-size: 18px;
      font-weight: 300;
      color: #383737;
      text-align: center;
      line-height: 38px;
    }
  }
}
.gray_bg {
  background: #F4F5FA;
}
.secondPlate {
  .hy_index_fajg{
    display: flex;
    justify-content: center;
    height: 598px;
    .hy_fajg_body{
      width: 1200px;
      height: 598px;
      display: flex;
      background: #FFFFFF;
      border-radius: 38px 0 0 38px;
      border: 1px solid #DADADA;
      position: relative;
      .fajg_body_left{
        width: 678px;
        padding-left: 40px;
        padding-top: 38px;
        margin-right: 42px;
        .fajg_pictures{
          height: 188px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img{
            width: 215px;
            height: 188px;
          }
        }
        .fajg_architecture{
          margin-top: 10px;
          .fajg_archi_body{
            height: 92px;
            background: #FFFFFF;
            box-shadow: 0 3px 3px 0 rgba(233, 233, 233, 0.5);
            border-radius: 6px;
            border: 1px solid #EBEBEB;
            margin-bottom: 22px;
            display: flex;
            h4{
              flex-shrink: 0;
              width: 58px;
              height: 92px;
              background: #155FA1;
              border-radius: 6px 0 0 6px;
              border: 1px solid #155FA1;
              color: #fff;
              font-weight: 600;
              font-size: 16px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 20px;
            }
            .fajg_body_details{
              flex: 1;
              padding: 24px 22px;
              display: flex;
              span{
                width: 130px;
                height: 45px;
                border-radius: 6px;
                border: 1px solid #155FA1;
                font-size: 14px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 45px;
                text-align: center;
                margin-right: 15px;
              }
              span:nth-of-type(4){
                margin-right: 0;
              }
            }
          }
          .fajg_archi_body:nth-of-type(3){
            margin-bottom: 0;
          }
        }
      }
      .fajg_body_right{
        position: absolute;
        right: 0;
        top: -2px;
        bottom: -2px;
        width: 355px;
        padding: 30px;
        border: 1px solid #155FA1;
        background: #155FA1 url("~@/assets/imgs/solution/archi_bg.png") no-repeat 99px 327px;
        background-size: 234px 234px;
        .archi_instruction{
          height: 252px;
          padding-bottom: 24px;
          border-bottom: 1px dotted #fff;
          margin-bottom: 28px;
        }
        h5{
          font-size: 18px;
          color: #fff;
          font-weight: 400;
        }
        p, li{
          font-size: 16px;
          color: #fff;
          font-weight: 400;
          line-height: 28px;
        }
        li:before{
          content: '';
          width: 4px;
          height: 4px;
          display: inline-block;
          border-radius: 50%;
          background: #fff;
          margin-right: 5px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>